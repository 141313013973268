import {
  defineGetServerData
} from "./chunk.FD6A6SJ5.js";
import {
  Server
} from "./chunk.WLUKVG5Y.js";

// universal_index.tsx?intercept
import React2 from "react";

// src/pages/index/index.tsx
import React from "react";
import { Link } from "react-router-dom";

// src/assets/logo.svg
var logo_default = "/assets/svg/logo.5d5d9eef.svg";

// src/pages/index/index.tsx
var App = ({ repos = [] }) => {
  React.useEffect(() => {
    console.log("useEffect called");
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "App"
  }, /* @__PURE__ */ React.createElement("header", {
    className: "App-header"
  }, /* @__PURE__ */ React.createElement("img", {
    src: logo_default,
    className: "App-logo",
    alt: "logo"
  }), /* @__PURE__ */ React.createElement("p", null, "Edit ", /* @__PURE__ */ React.createElement("code", null, "pages/index/index.tsx"), " and save to test HMR updates."), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("a", {
    className: "App-link",
    href: "https://reactjs.org",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Learn React"), " | ", /* @__PURE__ */ React.createElement("a", {
    className: "App-link",
    href: "https://speedy.web.bytedance.net/ssr/introduction.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "SSR Docs")), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement(Link, {
    className: "App-link",
    to: "/demo"
  }, "SSR Demo"))));
};
var index_default = App;

// src/pages/index/prefetch.ts
var prefetch_default = defineGetServerData(async (context) => {
  console.log(context.pathname);
  console.log(context.query);
  return {};
});

// universal_index.tsx?intercept
var Component = (props) => {
  return /* @__PURE__ */ React2.createElement(Server, {
    ...props
  }, /* @__PURE__ */ React2.createElement(index_default, null));
};
export {
  Component,
  prefetch_default as getServerSideProps
};
